<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить клубную карту</h1>
      </div>
    </div>
    <ClubCardAddForm ref="cardAddForm" />
    <form class="form">
      <div class="btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.CLUB_CARD_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>

import ClubCardAddForm from "@component/Form/ClubCardAddForm";

export default {
  name: "ClubCardAdd",
  components: {
    ClubCardAddForm,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    async save() {

      let formData = this.$refs.cardAddForm.formSubmitGetData();
      if(!formData) { return; }

      RequestManager.ClubCard.addClubCard({
        userId  : formData.client.id,
        ownerId : formData.owner.id, // TODO: add owner support
        tariffId: formData.tariff.id,
      } ).then( (res) => {
        // this.$dialogs.alert('Клубная карта успешно добавлена').then(() => {});
        this.$toasts.push({
          message: 'Клубная карта успешно добавлена',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLUB_CARD_LIST });
      });

    }
  },
};
</script>

<style lang="scss" scoped>

</style>
