<template>
  <FveFieldTemplate>
    <!--
      :internal-search="false"
      :clear-on-select="false"
      :options-limit="300"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="true"
    -->
    <Multiselect


      :close-on-select="true"

      :multiple="multiple"
      open-direction="bottom"

      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"

      :required="required"
      autocomplete="off"

      :searchable="true"
      :options="list"
      :loading="isLoading"
      @search-change="asyncFind"

      selectLabel=""
      selectedLabel="Выбрано"
      deselectLabel="Удалить"
      deselectGroupLabel="Press enter to deselect group"
      selectGroupLabel="Press enter to select group"

      v-model="_value"

      track-by="id"
      label="name"
    >
      <template v-slot:noOptions>{{ searchText.length > 0 ? 'Пустой список' : 'Введите для поиска 2 и более символов...'}}</template>
      <template v-slot:noResult>{{ searchText.length > 1 ? 'По вашему запросу ничего не найдено.' : 'Введите для поиска более 2-х символов...'}}</template>
    </Multiselect>
  </FveFieldTemplate>
</template>

<script>

import FveFieldMixin from "@widgetFormValidate/src/Mixin/FveFieldMixin";
import Multiselect from 'vue-multiselect';

export default {
  mixins: [
    FveFieldMixin
  ],
  components: {
    Multiselect
  },
  data(){
    return {
      //
      multiple  : false,

      searchText: '',
      selected  : null,
      list      : [],
      isLoading : false
    };
  },
  props: {
    // значение по умолчанию (можно переопределить тип)
    value    : { type: Object, default: () => null },
  },
  methods: {

    // getItemId(item) {
    //   return item.id;
    // },
    // getItemName(item) {
    //   return `${item.last_name} ${item.first_name} ${item.middle_name}`;
    // },
    // request() {
    //   return RequestManager.Trainer.getTrainerList({limit:20, search: query}).then((response) => {
    //     return response.results;
    //   });
    // },

    asyncFind (query, minLen = 1) {
      this.searchText = query;
      if(this.searchText.length < minLen) {
        this.list = [];
        return;
      }

      this.isLoading = true;
      this.request(query).then( (list) => {

        this.list = list.map((item) => {
          return {
            id: this.getItemId(item) ,
            name: this.getItemName(item),
            origin: item,
          };
        });
      }).finally( () => {
        this.isLoading = false;
      });
    },
    prepareValue($event) {
      return $event;
    },
    isEmpty(value) {
      return value === '';
    },
    // eslint-disable-next-line
    validateFunction(str) {
      return 'SUCCESS';
    },
  },
  computed: {
    _value: {
      get() {
        return this.value ? {
          id: this.getItemId(this.value) ,
          name: this.getItemName(this.value),
          origin: this.value,
        } : {
          id    : '',
          name  : '',
          origin: null,
        };
      },
      set(value) {
        this.inputFormElement(value ? value.origin : value /* null */);
      }
    }
  }
};
</script>

<style lang="scss" >
// TODO use scope
.fve {
  @import "~vue-multiselect/dist/vue-multiselect.min";
  .multiselect__spinner {
    background: var(--fve-input--background-color);
    height: 40px;
  }
  .multiselect {
    margin: var(--fve-input--margin);
    .multiselect__select {
      width: 40px;
      height: 44px;
    }
    .multiselect__tags {
      min-height: 44px;
      padding: 8px 40px 0 16px;
      border: var(--fve-input--border);
      border-color: var(--fve-input--border-color);
      border-radius: var(--fve-input--border-radius);
      background-color: var(--fve-input--background-color);
      .multiselect__single {
        padding-top: 2px;
        padding-left: 0;
        margin-bottom: 0;
        font-family: var(--fve-input--font-family);
        font-size: var(--fve-input--font-size);
        font-weight: var(--fve-input--font-weight);
        color: var(--fve-input--font-color);
        background-color: rgba(0,0,0,0);
      }
    }
    .multiselect__input {
      min-height: 24px;
      font-family: var(--fve-input--font-family);
      font-size: var(--fve-input--font-size);
      font-weight: var(--fve-input--font-weight);
      color: var(--fve-input--font-color);
      background-color: rgba(0,0,0,0);
    }
    .multiselect__content-wrapper {
      background: var(--br-block-prime);
      border: 1px solid var(--br-block-prime);
    }
    .multiselect__option {
      color: #ACACAC;
      text-overflow: ellipsis;
      overflow: hidden;
      &--highlight {
        color: #343A40;
        background: var(--color-prime);
      }
      &--selected {
        color: #ACACAC;
        background: var(--br-block-sub);
        &.multiselect__option--highlight {
          color: var(--fve-input--font-color);
          background: var(--color-sub);
        }
      }
    }
  }
}

</style>
