<script>

import FveSelectAjaxV2Mixin from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Mixin";

export default {
  mixins: [
    FveSelectAjaxV2Mixin
  ],
  methods: {
    getItemId(item) {
      return item.id;
    },
    getItemName(item) {
      return `${item.last_name} ${item.first_name} ${item.middle_name}`;
    },
    request(query) {
      return RequestManager.Trainer.getTrainerList({limit:20, search: query}).then((response) => {
        return response.results;
      });
    },
  },
  mounted() {
    this.asyncFind('', -1);
  }
};
</script>
