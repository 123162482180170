<template>
  <form class="form">
    <fieldset class="form__fieldset">
      <legend class="form__legend">Основная информация</legend>
      <div class="row">
        <div class="col-6">
          <FveSelectAjaxV2Client
            label="Пользователь"
            name="client"
            required
            v-model="form.client"
          />
          <FveSelectObject
            label="Тренирующийся"
            name="owner"
            required
            v-model="form.owner"
            :optionList="ownerList"
            :optionGetKey="(obj) => { return obj.id.toString();}"
            :optionGetName="(obj) => { return `${obj.last_name} ${obj.first_name} ${obj.middle_name}`;}"
          />
          <FveSelectAjaxV2Trainer
            label="Тренер"
            name="trainer"
            required
            v-model="form.trainer"
          />
          <FveSelectObject
            label="Тариф"
            name="tariff"
            required
            v-model="form.tariff"
            :optionList="tariffList"
            :optionGetKey="(obj) => { return obj.id.toString();}"
            :optionGetName="(obj) => { return obj.representation;}"
          />

          <PanelTariff
            v-if="form.tariff && false"
            :name="form.tariff.name"
            :trainings="form.tariff.trainings"
            :length="form.tariff.length"
            :price="form.tariff.price"
            :type="form.tariff.type"
            :club="form.tariff.club ? form.tariff.club : '-'"
            :court="form.tariff.court ? form.tariff.court.name : '-'"
            :trainer="`${form.trainer.last_name} ${form.trainer.first_name} ${form.trainer.middle_name}`"
            :tag="form.tariff.tag.tag"
          />
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>

//
import FveSelectObject        from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";
import FveSelectAjaxV2Client  from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Client";
import FveSelectAjaxV2Trainer from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Trainer";
// import FveSelectAjaxV2Tariff  from "@widgetFormValidate/src/Element/Custom/FveSelectAjaxV2Tariff";

//
import PanelTariff from "@component/Module/PanelTariff";

//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveSelectObject,
    FveSelectAjaxV2Client,
    FveSelectAjaxV2Trainer,
    // FveSelectAjaxV2Tariff,
    PanelTariff,
  },
  data(){
    return {};
  },
  methods: {
    formSchema() {
      return {
        client      : { type: Object, default: () => { return null; } },
        owner       : { type: Object, default: () => { return null; } },
        trainer     : { type: Object, default: () => { return null; } },
        tariff      : { type: Object, default: () => { return null; } },
      };
    },
  },

  computed: {
    ownerList() {
      return this.form.client ? this.form.client.family_set : [];
    },

    tariffList() {
      return this.form.trainer ? this.form.trainer.tariff_set : [];
    }
  },

  watch: {
    'form.client'(newValue, oldValue) {
      if(!newValue) {
        this.form.owner = null;
        return;
      }

      if( !oldValue || (newValue.id !== oldValue.id) ) {
        this.form.owner = newValue.family_set[0];
      }
    }
  },
};

</script>

<style lang="scss" scoped>

</style>
